import type * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import type { ReactNode } from "react";
import * as React from "react";
import { Checkbox } from "@ui/components/ui/Checkbox/Checkbox";
import { Label } from "@ui/components/ui/Label";
import { cn } from "@ui/utils";

type FormCheckboxProps = {
  id: string;
  label: string | ReactNode;
} & React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>;

const FormCheckbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  FormCheckboxProps
>(({ className, id, label, ...props }, ref) => (
  <div className={cn("flex items-center space-x-2 text-blue-700", className)}>
    <Checkbox id={id} {...props} ref={ref} />
    <div className="grid gap-1.5 leading-none">
      <Label htmlFor={id} className={"cursor-pointer"}>
        {label}
      </Label>
    </div>
  </div>
));

FormCheckbox.displayName = "FormCheckbox";

export { FormCheckbox };
