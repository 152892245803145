"use client";

import {
  Content,
  Portal,
  Root,
  Provider as TooltipProvider,
  Trigger,
} from "@radix-ui/react-tooltip";
import { cn } from "@ui/utils";
import * as React from "react";

const Tooltip = Root;

const TooltipTrigger = Trigger;
const TooltipPortal = Portal;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof Content>,
  React.ComponentPropsWithoutRef<typeof Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <Content
    ref={ref}
    sideOffset={sideOffset}
    className={cn(
      "z-50 overflow-hidden rounded-md border bg-popover px-3 py-1.5 text-sm text-popover-foreground shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
      className,
    )}
    {...props}
  />
));
TooltipContent.displayName = Content.displayName;

const WithTooltip = (
  props: React.PropsWithChildren<{ tooltip?: React.ReactNode }>,
) => {
  if (props.tooltip) {
    return (
      <Tooltip delayDuration={200}>
        <TooltipTrigger>{props.children}</TooltipTrigger>
        <TooltipPortal>
          <TooltipContent className="max-w-96 text-pretty">
            {props.tooltip}
          </TooltipContent>
        </TooltipPortal>
      </Tooltip>
    );
  }
  return props.children;
};

export {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipProvider,
  TooltipTrigger,
  WithTooltip,
};
