"use client";

import * as React from "react";
import * as SliderPrimitive from "@radix-ui/react-slider";

import { cn } from "../../../utils/classnames";

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    discrete?: boolean;
    hideRange?: boolean;
  }
>(({ className, discrete, hideRange = false, ...props }, ref) => {
  const min = props.min ?? 0;
  const max = props.max ?? 1;
  const step = props.step ?? 0.01;
  const dots = discrete && max && step ? Math.floor((max - min) / step) + 1 : 0;

  return (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        "relative flex w-full touch-none select-none items-center",
        className,
      )}
      {...props}
    >
      <SliderPrimitive.Track className="relative h-2 w-full grow rounded-full bg-secondary">
        {!hideRange && (
          <SliderPrimitive.Range className="absolute h-full rounded-full bg-primary" />
        )}
        <div className={"relative mx-1.5 flex items-center justify-between"}>
          {dots
            ? Array.from(Array(dots).keys()).map((idx) => {
                return (
                  <span
                    key={idx}
                    className={cn(
                      "pointer-events-none h-2 w-2 rounded-full",
                      "bg-gray-400",
                    )}
                  />
                );
              })
            : null}
        </div>
      </SliderPrimitive.Track>

      <SliderPrimitive.Thumb className="block h-5 w-5 rounded-full bg-primary transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50" />
    </SliderPrimitive.Root>
  );
});
Slider.displayName = SliderPrimitive.Root.displayName;

export { Slider };
