import type { PropsWithChildren } from "react";

import { cn } from "../../../utils/classnames";

export const RequiredDot = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  return (
    <>
      {children}
      <span className={cn("ml-1", className)}>*</span>
    </>
  );
};
