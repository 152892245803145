import * as React from "react";

import type { InputProps } from "./Input";
import type { InputWrapperProps } from "./InputWrapper";
import { Input } from "./Input";
import { InputWrapper } from "./InputWrapper";

export interface FormInputProps extends InputProps, InputWrapperProps {}

const FormInput = React.forwardRef<HTMLInputElement, FormInputProps>(
  (
    {
      label,
      id,
      error,
      warning,
      required,
      showEmptyDivs = false,
      wrapperClassName,
      tooltip,
      labelTooltip,
      reverseToolTip,
      tooltipAsError,
      ...rest
    },
    ref,
  ) => {
    const variant = error ? "error" : warning ? "warning" : "default";
    return (
      <InputWrapper
        id={id}
        required={required}
        wrapperClassName={wrapperClassName}
        label={label}
        warning={warning}
        error={error}
        showEmptyDivs={showEmptyDivs}
        tooltip={tooltip}
        labelTooltip={labelTooltip}
        variant={variant}
        reverseToolTip={reverseToolTip}
        tooltipAsError={tooltipAsError}
      >
        <Input ref={ref} id={id} {...rest} variant={variant} />
      </InputWrapper>
    );
  },
);

FormInput.displayName = "FormInput";

export { FormInput };
