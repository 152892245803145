"use client";

import * as React from "react";

import type { ButtonProps } from "./Button";
import { cn } from "../../../utils/classnames";
import { ToggleGroup, ToggleGroupItem } from "../ToggleGroup";
import { Button } from "./Button";

const ButtonToggleGroup = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<typeof ToggleGroup> & {
    buttons: (ButtonProps & { label?: string })[];
  }
>(({ buttons, ...rest }, ref) => (
  <ToggleGroup ref={ref} {...rest}>
    {buttons.map((buttonProps) => {
      return (
        <ToggleGroupItem
          key={String(buttonProps.value)}
          value={String(buttonProps.value)}
          asChild
        >
          <Button
            key={String(buttonProps.value)}
            variant={buttonProps.variant ?? "outline"}
            {...buttonProps}
            className={cn(
              "rounded-none border-r-0 first:rounded-l-lg last:rounded-r-lg last:border-r hover:bg-accent data-[state=on]:bg-primary data-[state=on]:text-primary-foreground",
              buttonProps.className,
            )}
          >
            {buttonProps.label ?? buttonProps.children}
          </Button>
        </ToggleGroupItem>
      );
    })}
  </ToggleGroup>
));

ButtonToggleGroup.displayName = "ButtonToggleGroup";

export { ButtonToggleGroup };
