import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";

import type { InputProps } from "../Input";
import type { InputWrapperProps } from "../Input/InputWrapper";
import { SelectGroup, SelectValue } from ".";
import { cn } from "../../../utils/classnames";
import { InputWrapper } from "../Input/InputWrapper";
import { inputVariants } from "../Input/style";
import { SelectContent } from "./SelectContent";
import { SelectItem } from "./SelectItem";
import { SelectLabel } from "./SelectLabel";
import { SelectTrigger } from "./SelectTrigger";

export interface SelectOption extends SelectPrimitive.SelectItemProps {
  label?: string;
  isGroupLabel?: boolean;
}

export interface SelectInputProps
  extends Omit<InputProps, "onChange">,
    InputWrapperProps {
  options: SelectOption[];
  renderOption?: (option: SelectOption) => React.ReactNode;
  renderValue?: (value?: SelectOption) => React.ReactNode;
  autoWidth?: boolean;
  rootProps?: SelectPrimitive.SelectProps;
  triggerProps?: SelectPrimitive.SelectTriggerProps;
  contentProps?: SelectPrimitive.SelectContentProps;
  onChange?: (value: string) => void;
  clearable?: () => void;
}

const SelectInput = React.forwardRef<HTMLInputElement, SelectInputProps>(
  (
    {
      label,
      id,
      error,
      multiple = false, // To Do support multiple values
      warning,
      required,
      showEmptyDivs = false,
      wrapperClassName,
      labelTooltip,
      tooltip,
      reverseToolTip,
      tooltipAsError,
      renderOption,
      renderValue,
      rootProps,
      triggerProps,
      contentProps,
      placeholder,
      options,
      value,
      disabled,
      defaultValue,
      startAdornment,
      endAdornment,
      name,
      clearable,
      onChange,
    },
    ref,
  ) => {
    const variant = error ? "error" : warning ? "warning" : "default";
    const selectedOption = options.find((o) => o.value === value);
    const defaultOption = options.find((o) => o.value === defaultValue);

    return (
      <InputWrapper
        id={id}
        required={required}
        wrapperClassName={wrapperClassName}
        label={label}
        warning={warning}
        error={error}
        showEmptyDivs={showEmptyDivs}
        tooltip={tooltip}
        labelTooltip={labelTooltip}
        variant={variant}
        reverseToolTip={reverseToolTip}
        tooltipAsError={tooltipAsError}
      >
        <SelectPrimitive.Root
          value={value ? String(value) : undefined}
          name={name}
          {...rootProps}
          disabled={disabled}
          onValueChange={onChange}
        >
          <SelectTrigger
            {...triggerProps}
            className={cn(
              "min-w-56 placeholder:px-3",
              inputVariants({ variant }),
              "py-0",
              triggerProps?.className,
            )}
          >
            {renderValue ? (
              renderValue(selectedOption ?? defaultOption)
            ) : (
              <>
                {startAdornment && (
                  <div className="flex h-full items-center border-e pr-3">
                    {startAdornment}
                  </div>
                )}
                <SelectValue placeholder={placeholder} ref={ref}>
                  {selectedOption?.label ?? defaultOption?.label}
                </SelectValue>
                {endAdornment && (
                  <div className="flex h-full items-center border-s pl-3">
                    {endAdornment}
                  </div>
                )}
                {clearable && (
                  <button
                    type="button"
                    className="px-2"
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      clearable();
                    }}
                  >
                    <i className="ri-close-line" />
                  </button>
                )}
              </>
            )}
          </SelectTrigger>
          <SelectContent
            {...contentProps}
            className={cn("max-h-80 overflow-y-auto", contentProps?.className)}
          >
            <SelectGroup>
              {options.map((option) => {
                const { label, isGroupLabel, ...optionProps } = option;
                if (isGroupLabel) {
                  return (
                    <SelectLabel key={option.value} {...optionProps}>
                      {label}
                    </SelectLabel>
                  );
                }
                return (
                  <SelectItem key={option.value} {...optionProps}>
                    {renderOption ? renderOption(option) : label}
                  </SelectItem>
                );
              })}
            </SelectGroup>
          </SelectContent>
        </SelectPrimitive.Root>
      </InputWrapper>
    );
  },
);

SelectInput.displayName = "SelectInput";

export { SelectInput };
