"use client";

import * as SelectPrimitive from "@radix-ui/react-select";

import { SelectContent } from "./SelectContent";
import { SelectInput } from "./SelectInput";
import { SelectItem } from "./SelectItem";
import { SelectLabel } from "./SelectLabel";
import { SelectSeparator } from "./SelectSeparator";
import { SelectTrigger } from "./SelectTrigger";

const Select = SelectPrimitive.Root;
const SelectGroup = SelectPrimitive.Group;
const SelectValue = SelectPrimitive.Value;
const SelectItemText = SelectPrimitive.ItemText;

export {
  Select,
  SelectContent,
  SelectGroup,
  SelectInput,
  SelectItem,
  SelectItemText,
  SelectLabel,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
};
