import type {
  TooltipContentProps,
  TooltipProps,
  TooltipTriggerProps,
} from "@radix-ui/react-tooltip";
import type { VariantProps } from "class-variance-authority";
import type * as React from "react";
import { Label } from "@ui/components/ui";
import { RequiredDot } from "@ui/components/ui/RequiredDot/RequiredDot";

import { cn } from "../../../utils/classnames";
import {
  Tooltip,
  TooltipContent,
  TooltipPortal,
  TooltipTrigger,
} from "../Tooltip";
import { inputWrapperVariants } from "./style";

export interface InputWrapperProps
  extends React.PropsWithChildren,
    Pick<React.InputHTMLAttributes<HTMLInputElement>, "id" | "required">,
    VariantProps<typeof inputWrapperVariants> {
  label?: string | React.ReactNode;
  error?: string | React.ReactNode;
  warning?: string | React.ReactNode;
  showEmptyDivs?: boolean;
  wrapperClassName?: string;
  reverseToolTip?: boolean;
  tooltipAsError?: boolean;
  tooltip?: {
    root?: TooltipProps;
    trigger?: TooltipTriggerProps;
    content?: TooltipContentProps;
  };
  labelTooltip?: {
    root?: TooltipProps;
    trigger?: TooltipTriggerProps;
    content?: TooltipContentProps;
  };
}

const InputWrapper = (props: InputWrapperProps) => {
  const {
    id,
    label,
    children,
    error,
    required,
    showEmptyDivs = false,
    tooltip,
    tooltipAsError, // Render RHF error in tooltip instead of error message
    reverseToolTip, // Reverse order of tooltip
    labelTooltip,
    variant,
    warning,
    wrapperClassName,
  } = props;

  return (
    <div
      className={cn(
        "grid h-min w-full",
        inputWrapperVariants({ variant }),
        wrapperClassName,
      )}
    >
      <Label
        className={cn(
          "mb-1",
          error ? "text-red-800" : warning ? "text-yellow-600" : "",
          showEmptyDivs ? "min-h-[0.875rem]" : "empty:hidden",
        )}
        htmlFor={id}
      >
        {label}
        {required && (
          <RequiredDot className={cn(inputWrapperVariants({ variant }))} />
        )}
        {labelTooltip && (
          <Tooltip delayDuration={200} {...labelTooltip.root}>
            <TooltipTrigger
              type="button"
              {...labelTooltip.trigger}
              className={cn(
                "px-2",
                inputWrapperVariants({ variant }),
                labelTooltip.trigger?.className,
              )}
            >
              {labelTooltip.trigger?.children ? (
                labelTooltip.trigger.children
              ) : (
                <i className="ri-error-warning-line text-sm" />
              )}
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent
                {...labelTooltip.content}
                className={cn(
                  inputWrapperVariants({ variant }),
                  labelTooltip.content?.className,
                )}
              >
                {labelTooltip.content?.children}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )}
      </Label>

      <div
        className={cn(
          "flex w-full",
          reverseToolTip ? "flex-row-reverse" : "flex-row",
        )}
      >
        {children}
        {tooltip && (
          <Tooltip delayDuration={200} {...tooltip.root}>
            <TooltipTrigger
              type="button"
              {...tooltip.trigger}
              className={cn(
                "px-2",
                inputWrapperVariants({ variant }),
                tooltip.trigger?.className,
              )}
            >
              {tooltip.trigger?.children ? (
                tooltip.trigger.children
              ) : (
                <i className="ri-question-line" />
              )}
            </TooltipTrigger>
            <TooltipPortal>
              <TooltipContent
                {...tooltip.content}
                className={cn(
                  inputWrapperVariants({ variant }),
                  tooltip.content?.className,
                )}
              >
                {tooltipAsError ? error ?? warning : tooltip.content?.children}
              </TooltipContent>
            </TooltipPortal>
          </Tooltip>
        )}
      </div>

      <span
        className={cn(
          "mt-1 line-clamp-1 inline-block overflow-hidden text-ellipsis text-xs",
          warning ? "text-yellow-600" : "",
          error ? "text-red-800" : "",
          showEmptyDivs ? "min-h-[0.875rem]" : "empty:hidden",
          tooltipAsError ? "hidden" : "",
        )}
      >
        {error ?? warning}
      </span>
    </div>
  );
};

export { InputWrapper };
