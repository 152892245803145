import { cva } from "class-variance-authority";

const inputErrorStyle = "text-red-800 border-red-800";
const inputWarningtyle = "text-yellow-600 border-yellow-600";

const inputVariants = cva(
  "flex h-10 w-full rounded-md border border-input bg-background text-sm ring-offset-background focus-within:ring-1 focus-within:ring-ring focus-within:ring-offset-2",
  {
    variants: {
      variant: {
        default: "",
        error: inputErrorStyle,
        warning: inputWarningtyle,
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

const inputWrapperVariants = cva("", {
  variants: {
    variant: {
      default: "",
      error: inputErrorStyle,
      warning: inputWarningtyle,
    },
  },
  defaultVariants: {
    variant: "default",
  },
});

export { inputVariants, inputWrapperVariants };
